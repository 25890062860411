* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: #fff;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  outline: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: #cb2027;
}
